import lodash from 'lodash';
import popper from 'popper.js';
import jquery from 'jquery';
import * as bootstrap from 'bootstrap';
import axios from 'axios';

window._ = lodash;
window.Popper = popper;
window.$ = window.jQuery = jquery;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.bootstrap = bootstrap;
